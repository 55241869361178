import React, { useEffect } from 'react';
import useAuth from '../src/providers/Auth/Auth.provider';
import FullPageSpinner from './components/TwFullPageProgress/TwFullPageProgress';
import * as Sentry from '@sentry/react';

if ('REACT_APP_DSN' in process.env) {
  Sentry.init({
    dsn: process.env.REACT_APP_DSN,
    tracesSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: process.env.REACT_APP_VERSION
  });
}

const lazyRetry = function <T>(appName: string, componentImport: () => Promise<T>): Promise<T> {
  return new Promise((resolve, reject) => {
    const urlParams = new URLSearchParams(window.location.search);
    const retryKey = `retry-${appName}`;
    const hasRetried = urlParams.get(retryKey);

    // Try to import the component
    componentImport()
      .then((component) => {
        // Clear the retry flag from the URL on success
        if (hasRetried) {
          urlParams.delete(retryKey);
          window.history.replaceState(
            null,
            '',
            `${window.location.pathname}?${urlParams.toString()}`
          );
        }
        resolve(component);
      })
      .catch((error) => {
        if (error.message.includes('ChunkLoadError:')) {
          if (!hasRetried) {
            console.error('ChunkLoadError detected, retrying...');
            urlParams.set(retryKey, 'true');
            window.location.replace(`${window.location.pathname}?${urlParams.toString()}`); // Perform a hard reload with the retry flag
          } else {
            console.error('ChunkLoadError persists after retry, rejecting...');
            reject(error);
          }
        } else {
          reject(error);
        }
      });
  });
};

const PublicApp = React.lazy(() => lazyRetry('public-app', () => import('./PublicApp')));
const App = React.lazy(() => lazyRetry('app', () => import('./App')));

function TruckbaseApp(): JSX.Element {
  const {
    data: { userAuthorized: isAuth }
  } = useAuth();

  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      {isAuth ? <App /> : <PublicApp />}
    </React.Suspense>
  );
}

export default TruckbaseApp;
