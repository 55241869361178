import { PayloadAction, createSlice, nanoid } from '@reduxjs/toolkit';
import {
  Trip,
  TripCommodities,
  TripDriverInfo,
  TripDriverOtherPay,
  TripDriverOverrideData,
  TRIP_STATUS
} from '../../api/trips/trips.model';
import { AddDriverParams, MIN_STOP_COUNT, SliceStop, reorder } from '../orders/ordersSlice';
import { Equipment, EquipmentType } from '../../api/equipment/equipment.model';
import { OrderAttachment, OrdersTripsDocumentParams } from '../../api/orders/orders.model';

export interface LtlSliceTrip extends Partial<Trip> {
  tripId?: string;
  order: number;
  selected_dispatcher: string | undefined;
  selected_trailer: string | undefined;
  selected_truck: string | undefined;
  selected_carrier: string | undefined;
  provider_trip_stops: SliceStop[];
  selected_drivers: Partial<TripDriverInfo>[];
  ui_id: string;
  empty_miles: number | null;
  is_empty_miles_auto: boolean;
  loaded_miles: number | null;
  is_loaded_miles_auto: boolean;
  trip_commodities: TripCommodities[];
  documents?: OrdersTripsDocumentParams[];
  existing_documents?: OrderAttachment[];
  attachments?: OrderAttachment[];
  ordersInTrip: { id: string; readable_id: string | number }[];
  total_trip_miles: number | null;
}

export const DEFAULT_STOP: SliceStop = {
  order: 0,
  ui_id: '',
  provider_date: null,
  provider_location: null,
  provider_stop_type: null,
  provider_time_from: null,
  provider_time_to: null,
  provider_stop_identifier: '',
  provider_edi_stop_identifier: undefined,
  stop_type: undefined,
  provider_stop_status: undefined,
  segment_id: '',
  provider_stop_in_time_overriden: false,
  provider_stop_out_time_overriden: false,
  is_shared_stop: false,
  order_data: undefined
};

export const DEFAULT_STOP_1_UI_ID = nanoid();
export const DEFAULT_STOP_2_UI_ID = nanoid();
export const DEFAULT_TRIP_UI_ID = nanoid();

export const INITIAL_TRIP: LtlSliceTrip = Object.freeze({
  tripId: undefined,
  order: 1,
  sort_order: 1,
  ui_id: DEFAULT_TRIP_UI_ID,
  selected_dispatcher: '',
  selected_carrier: '',
  selected_trailer: '',
  selected_truck: '',
  selected_drivers: [{ send_driver_link: false }],
  provider_trip_stops: [
    {
      ...DEFAULT_STOP,
      order: 1,
      ui_id: DEFAULT_STOP_1_UI_ID,

      segment_id: DEFAULT_TRIP_UI_ID
    },
    {
      ...DEFAULT_STOP,
      order: 2,
      ui_id: DEFAULT_STOP_2_UI_ID,

      segment_id: DEFAULT_TRIP_UI_ID
    }
  ],
  empty_miles: null,
  loaded_miles: null,
  is_empty_miles_auto: false,
  is_loaded_miles_auto: false,
  instructions: '',
  trip_commodities: [
    {
      ui_uid: nanoid(),
      description: '',
      customer: '',
      customer_number: '',
      order_id: '',
      quantity: null,
      weight: null,
      order_readable_id: '',
      packaging_unit: null
    }
  ],
  status: TRIP_STATUS.DRAFT,
  documents: [],
  existing_documents: [],
  attachments: [],
  ordersInTrip: [],
  total_trip_miles: null
});

const tripSlice = createSlice({
  name: 'Trip',
  initialState: INITIAL_TRIP,
  reducers: {
    clearLtlTripState: (state) => {
      const currentTripId = state.tripId;
      return {
        ...INITIAL_TRIP,
        tripId: currentTripId // Needed to open the trip overlay after creating it
      };
    },
    clearTripStateExceptId: (state) => {
      const tripState = { ...INITIAL_TRIP };
      delete tripState.tripId;
      return {
        ...state,
        ...tripState
      };
    },
    setTripSliceState: (state, action: PayloadAction<LtlSliceTrip>) => {
      return {
        ...state,
        ...action.payload
      };
    },
    updateTripId: (state, action: PayloadAction<string | undefined>) => {
      state.tripId = action.payload;
    },
    updateTripCommodity: (state, action: PayloadAction<TripCommodities[]>) => {
      state.trip_commodities = action.payload;
    },
    updateTripStop: (
      state,
      action: PayloadAction<{ stop: SliceStop; clearDriverPay?: boolean }>
    ) => {
      const { stop, clearDriverPay } = action.payload;

      const newStops = [...state.provider_trip_stops];
      const stopToUpdate = newStops.findIndex((s) => s.ui_id === stop.ui_id);
      newStops[stopToUpdate] = stop;

      if (clearDriverPay) {
        state.selected_drivers = state.selected_drivers.map((driver) => {
          return {
            ...driver,
            driver_pay_override: undefined,
            driver_pay_override_data: undefined
          };
        });
        state.empty_miles = null;
        state.is_empty_miles_auto = false;
        state.loaded_miles = null;
        state.is_loaded_miles_auto = false;
      }

      state.provider_trip_stops = newStops;
    },
    addStopToTripInOrder: (state, action: PayloadAction<number>) => {
      const order = action.payload;
      const currentStopsLength = state.provider_trip_stops.length;
      const tripId = state.ui_id;
      let newStops: SliceStop[] = [];
      const newStopId = nanoid();

      if (order === 1) {
        newStops = [
          {
            ...DEFAULT_STOP,
            order: order,
            ui_id: newStopId,
            segment_id: tripId
          },
          ...state.provider_trip_stops
        ];
      } else if (order === currentStopsLength + 1) {
        newStops = [
          ...state.provider_trip_stops,
          {
            ...DEFAULT_STOP,
            order: order,
            ui_id: newStopId,
            segment_id: tripId
          }
        ];
      } else {
        newStops = [
          ...state.provider_trip_stops.slice(0, order - 1),
          {
            ...DEFAULT_STOP,
            order: order,
            ui_id: newStopId,
            segment_id: tripId
          },
          ...state.provider_trip_stops.slice(order - 1)
        ];
      }

      state.selected_drivers = state.selected_drivers.map((driver) => {
        return {
          ...driver,
          driver_pay_override: undefined,
          driver_pay_override_data: undefined
        };
      });

      newStops.forEach((s, i) => {
        s.order = i + 1;
        s.sort_order = i + 1;
      });
      const stopsHaveLocation = newStops.every((s) => s.provider_location);
      state.empty_miles = !newStops[0].provider_location ? null : state.empty_miles;
      state.loaded_miles = stopsHaveLocation ? state.loaded_miles : null;
      state.provider_trip_stops = newStops;
    },
    deleteTripStop: (state, action: PayloadAction<string>) => {
      const stopsToKeep = [...state.provider_trip_stops];
      const stopToDelete = state.provider_trip_stops.find((stop) => stop.ui_id === action.payload);
      if (!stopToDelete) return;

      stopsToKeep.splice(
        stopsToKeep.findIndex((s) => s.ui_id === stopToDelete.ui_id),
        1
      );

      state.selected_drivers = state.selected_drivers.map((driver) => {
        return {
          ...driver,
          driver_pay_override: undefined,
          driver_pay_override_data: undefined
        };
      });

      stopsToKeep.forEach((s, i) => {
        s.order = i + 1;
        s.sort_order = i + 1;
      });

      state.provider_trip_stops = stopsToKeep;
    },
    reorderTripStop: (
      state,
      action: PayloadAction<{ originalIndex: number; newIndex: number }>
    ) => {
      const { originalIndex, newIndex } = action.payload;

      const stops = [...state.provider_trip_stops];

      const reorderedSubItems = reorder(stops, originalIndex, newIndex);

      const firstStopInSegmentOrder = state.provider_trip_stops[0];
      if (!firstStopInSegmentOrder) return;

      reorderedSubItems.forEach((s, i) => {
        s.order = i + 1;
        s.sort_order = i + 1;

        return s;
      });

      state.provider_trip_stops = reorderedSubItems;
    },
    addTripEquipment: (
      state,
      action: PayloadAction<{
        equipment: Pick<Equipment, 'id' | 'type'>;

        abortUpdate?: boolean;
      }>
    ) => {
      const { abortUpdate, equipment } = action.payload;
      if (abortUpdate) return;

      if (equipment.id === EquipmentType.truck) {
        state.selected_truck = equipment.id;
      }

      if (equipment.type === EquipmentType.trailer) {
        state.selected_trailer = equipment.id;
      }
    },
    addTripDriver: (state, action: PayloadAction<{ params: AddDriverParams }>) => {
      const { driver, index, selectedTruck, selectedTrailer, selectedCarrier, sendDriverLink } =
        action.payload.params;

      const newDriver: TripDriverInfo = {
        id: driver.id,
        send_driver_link: sendDriverLink ?? false,
        is_primary: index === 0
      };

      state.selected_drivers[index] = newDriver;

      if (!state.id) {
        if (selectedTruck) {
          state.selected_truck = selectedTruck;
        }
        if (selectedTrailer) {
          state.selected_trailer = selectedTrailer;
        }
        if (selectedCarrier) {
          state.selected_carrier = selectedCarrier;
        }
      }
    },
    addTripCarrier: (
      state,
      action: PayloadAction<{
        carrierId: string;
        abortUpdate?: boolean;
      }>
    ) => {
      const { abortUpdate, carrierId } = action.payload;
      if (abortUpdate) return;

      state.selected_carrier = carrierId;
    },
    addTripSecondDriver: (state, action: PayloadAction) => {
      const newDrivers = [...state.selected_drivers];

      newDrivers.push({ send_driver_link: false });

      // When adding a new driver we clear the driver pay for the previous drivers
      const newDriversCleared = newDrivers.map((driver) => {
        return {
          ...driver,
          driver_pay_override: undefined,
          driver_pay_override_data: undefined
        };
      });
      state.selected_drivers = newDriversCleared;
    },
    deleteTripDriver: (state, action: PayloadAction<{ driverOrder: number }>) => {
      const { driverOrder } = action.payload;
      const currentDrivers = [...state.selected_drivers];
      const newDrivers = currentDrivers.filter((_, index) => index !== driverOrder - 1);

      // When removing a driver we clear the estimated gross pay for other drivers
      const newDriversCleared = newDrivers.map((driver) => {
        return {
          ...driver,
          driver_pay_override: undefined,
          driver_pay_override_data: undefined
        };
      });
      state.selected_drivers = newDriversCleared;
    },
    updateTripDriverPayData: (
      state,
      action: PayloadAction<{
        driverIndex: number;
        grossPayEstimateData: TripDriverOverrideData;
      }>
    ) => {
      const { driverIndex, grossPayEstimateData } = action.payload;
      const newDrivers = [...state.selected_drivers];

      newDrivers[driverIndex].driver_pay_override_data = grossPayEstimateData;

      state.selected_drivers = newDrivers;
    },

    updateTripSelectedDispatcher: (state, action: PayloadAction<string | undefined>) => {
      state.selected_dispatcher = action.payload;
    },
    updateTripSelectedDriver: (
      state,
      action: PayloadAction<{
        driver: TripDriverInfo;
        index: number;

        selectedTruck?: string;
        selectedCarrier?: string;
        selectedTrailer?: string;
      }>
    ) => {
      const { driver, index, selectedTruck, selectedCarrier, selectedTrailer } = action.payload;
      const newDrivers = [...state.selected_drivers];
      newDrivers[index] = driver;

      state.selected_drivers = newDrivers;

      if (!state.id) {
        if (selectedTruck) {
          state.selected_truck = selectedTruck;
        }
        if (selectedTrailer) {
          state.selected_trailer = selectedTrailer;
        }
        if (selectedCarrier) {
          state.selected_carrier = selectedCarrier;
        }
      }
    },
    updateTripOtherPayItemsData: (
      state,
      action: PayloadAction<{
        driverIndex: number;
        otherPayItemsData: TripDriverOtherPay[];
      }>
    ) => {
      const { driverIndex, otherPayItemsData } = action.payload;
      const newDrivers = [...state.selected_drivers];
      newDrivers[driverIndex].trip_driver_pay_other_payment = otherPayItemsData;

      state.selected_drivers = newDrivers;
    },

    updateTripSelectedTrailer: (state, action: PayloadAction<{ value?: string }>) => {
      const { value } = action.payload;

      state.selected_trailer = value;
    },
    updateTripSelectedTruck: (state, action: PayloadAction<{ value?: string }>) => {
      const { value } = action.payload;

      state.selected_truck = value;
      state.empty_miles = null;
    },
    updateTripSelectedCarrier: (state, action: PayloadAction<{ value?: string }>) => {
      const { value } = action.payload;
      state.selected_carrier = value;
    },
    updateTripNotes: (state, action: PayloadAction<string>) => {
      state.instructions = action.payload;
    },
    updateTripLoadedMiles: (
      state,
      action: PayloadAction<{ value: number | null; is_auto: boolean }>
    ) => {
      const { value, is_auto } = action.payload;

      state.loaded_miles = value;
      state.is_loaded_miles_auto = is_auto;
    },
    updateTripEmptyMiles: (
      state,
      action: PayloadAction<{ value: number | null; is_auto: boolean }>
    ) => {
      const { value, is_auto } = action.payload;

      state.empty_miles = value;
      state.is_empty_miles_auto = is_auto;
    },
    updateTripDocuments: (state, action: PayloadAction<OrdersTripsDocumentParams[]>) => {
      state.documents = action.payload;
    },
    updateTripAttachments: (state, action: PayloadAction<OrderAttachment[] | undefined>) => {
      state.attachments = action.payload;
    },
    setExistingTripDocuments: (state, action: PayloadAction<OrderAttachment[] | undefined>) => {
      state.existing_documents = action.payload;
    },
    updateExistingTripDocument: (state, action: PayloadAction<OrderAttachment>) => {
      const { id, ...rest } = action.payload;
      const newDocuments = state.existing_documents?.map((d) =>
        d.id === id ? { ...d, ...rest } : d
      );

      state.existing_documents = newDocuments;
    },
    setInitialOrdersInTrip: (
      state,
      action: PayloadAction<{ id: string; readable_id: string }[]>
    ) => {
      state.ordersInTrip = action.payload;
    },
    addOrderStopsToTrip: (state, action: PayloadAction<SliceStop[]>) => {
      // Add incoming stops to the current stops and sort them by date
      let newStops = [...state.provider_trip_stops, ...action.payload].sort((a, b) => {
        if (a.provider_date && b.provider_date) {
          return new Date(a.provider_date).valueOf() - new Date(b.provider_date).valueOf();
        } else {
          return 0;
        }
      });

      // remove empty stops
      newStops = newStops.filter((s) => s.provider_location);

      // check if new stops are the minumum required stops, if not add stops
      const stopsLenght = newStops.length;
      if (stopsLenght < MIN_STOP_COUNT) {
        for (let i = 0; i < 2 - stopsLenght; i++) {
          newStops.push({
            ...DEFAULT_STOP,
            order: 0,
            ui_id: nanoid()
          });
        }
      }

      // Assign ordering number to stops
      newStops.forEach((s, i) => {
        s.order = i + 1;
        s.sort_order = i + 1;
      });

      // clear driver pay when stops are added
      state.selected_drivers = state.selected_drivers.map((driver) => {
        return {
          ...driver,
          driver_pay_override: undefined,
          driver_pay_override_data: undefined
        };
      });

      state.empty_miles = null;
      state.loaded_miles = null;
      state.provider_trip_stops = newStops;
    },
    removeOrderStopsFromTrip: (state, action: PayloadAction<string>) => {
      const order_id = action.payload;

      const newStops = state.provider_trip_stops.filter((s) => s.order_data?.order_id !== order_id);

      // check if new stops are the minumum required stops
      const stopsLenght = newStops.length;
      if (stopsLenght < 2) {
        for (let i = 0; i < 2 - stopsLenght; i++) {
          newStops.push({
            ...DEFAULT_STOP,
            order: 0,
            ui_id: nanoid()
          });
        }
      }

      // Assign ordering number to stops
      newStops.forEach((s, i) => {
        s.order = i + 1;
        s.sort_order = i + 1;
      });
      // clear driver pay when stops are removed
      state.selected_drivers = state.selected_drivers.map((driver) => {
        return {
          ...driver,
          driver_pay_override: undefined,
          driver_pay_override_data: undefined
        };
      });
      state.empty_miles = null;
      state.loaded_miles = null;
      state.provider_trip_stops = newStops;
    },
    addOrderCommoditiesToTrip: (state, action: PayloadAction<TripCommodities[]>) => {
      const tripCommodities = [...state.trip_commodities, ...action.payload];

      // remove empty commodities
      state.trip_commodities = tripCommodities.filter(
        (c) => c.description !== '' || c.quantity || c.weight
      );
    },
    removeOrderCommoditiesFromTrip: (state, action: PayloadAction<string>) => {
      const order_id = action.payload;

      state.trip_commodities = state.trip_commodities.filter((c) => c.order_id !== order_id);
    },
    updateTripRouteTotalMileage: (state, action: PayloadAction<number | null>) => {
      state.total_trip_miles = action.payload;
    }
  }
});
export const {
  clearLtlTripState,
  clearTripStateExceptId,
  setTripSliceState,
  updateTripId,
  updateTripCommodity,
  updateTripStop,
  addStopToTripInOrder,
  deleteTripStop,
  reorderTripStop,
  addTripEquipment,
  addTripDriver,
  addTripCarrier,
  addTripSecondDriver,
  deleteTripDriver,
  updateTripDriverPayData,
  updateTripSelectedDispatcher,
  updateTripSelectedDriver,
  updateTripOtherPayItemsData,
  updateTripSelectedTrailer,
  updateTripSelectedTruck,
  updateTripSelectedCarrier,
  updateTripNotes,
  updateTripLoadedMiles,
  updateTripEmptyMiles,
  setInitialOrdersInTrip,
  addOrderStopsToTrip,
  removeOrderStopsFromTrip,
  addOrderCommoditiesToTrip,
  removeOrderCommoditiesFromTrip,
  updateTripRouteTotalMileage,
  updateTripDocuments,
  updateTripAttachments,
  setExistingTripDocuments,
  updateExistingTripDocument
} = tripSlice.actions;

export default tripSlice.reducer;
